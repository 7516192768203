import type { CookieConsentConfig } from 'vanilla-cookieconsent';

export const config: CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {},
        analytics: {},
        marketing: {}
    },
    language: {
        default: "es",
        autoDetect: "browser",
        translations: {
            es: {
                consentModal: {
                    title: "¡Usamos cookies!",
                    description: "Hola, este sitio web utiliza cookies esenciales para garantizar su correcto funcionamiento y cookies de seguimiento para comprender cómo interactúa con él. Estos últimos se establecerán solo después del consentimiento.",
                    closeIconLabel: "",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    showPreferencesBtn: "Gestionar preferencias",
                    footer: ""
                },
                preferencesModal: {
                    title: "Preferencias de Consentimiento",
                    closeIconLabel: "Cerrar modal",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    savePreferencesBtn: "Guardar preferencias",
                    serviceCounterLabel: "Servicios",
                    sections: [
                        {
                            title: "Uso de Cookies",
                            description: "Utilizamos cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea. Puede optar por aceptar/rechazar cada categoría cuando lo desee. Para obtener más detalles sobre las cookies y otros datos sensibles, lea la política de privacidad completa."
                        },
                        {
                            title: "Cookies Estrictamente Necesarias <span class=\"pm__badge\">Siempre Habilitado</span>",
                            description: "Estas cookies son esenciales para el buen funcionamiento del sitio web. Sin estas cookies, el sitio web no funcionaría correctamente.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Cookies de Funcionalidad",
                            description: "Esta categoría incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web. Estas cookies no almacenan ninguna información personal.",
                            linkedCategory: "functionality"
                        },
                        {
                            title: "Cookies Analíticas",
                            description: "Estas cookies permiten que el sitio web recuerde las elecciones que ha realizado en el pasado.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Cookies Publicitarias",
                            description: "Estas cookies recopilan información sobre cómo utiliza el sitio web, qué páginas visitó y en qué enlaces hizo clic. Todos los datos son anónimos y no se pueden utilizar para identificarle.",
                            linkedCategory: "marketing"
                        },
                        {
                            title: "Más información",
                            description: "Para cualquier consulta relacionada con nuestra política de cookies y sus opciones, contáctenos."
                        }
                    ]
                }
            }
        }
    }
};
